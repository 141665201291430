

import { createRouter,createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()

// import { getIsAuthenticated } from '@/utils/util.js'
import Home from '@/components/Home.vue'
// import store from '@/stores'
import Login from '@/components/Login.vue'
// import api from "@/utils/api.js";
import CaseDetails from '@/components/CaseDetails.vue'
// import { useChatStore } from "@/stores/chat";
// const chatStore = useChatStore(store)
// import { message } from 'ant-design-vue';
const routers = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/case/:id?",
        name: "CaseDetails",
        component: CaseDetails
    }
]
// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routers
})
// // 全局路由守卫，判断是否登陆
// router.beforeEach(async (to, from, next) => {
   
//     if ( !getIsAuthenticated() && to.name !== 'CaseDetails') {
//         if (to.query.code) {
//             const url = `/api/wechat/login?code=${to.query.code}`
//             api.post(url).then((res)=>{
//                   if (res.code == 0){
//                     sessionStorage.setItem('token', res.data)
//                     setTimeout(()=>{
//                         next({
//                             name: 'Home'
//                         })
//                     },500)
//                   }else{
//                     message.error('登陆失败')
//                   }
//                 })
//         }else{
//             chatStore.updateIsShowLogin(true)
//             next()
//         }
        
//     }else next()
// })

export default router;