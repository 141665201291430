<template>
  <div class="login-container flex flex-col">
    <div class="login-form">
      <div class="text-xl mb-4">快版权ChatGPT</div>
      <a-form
        ref="formRef"
        :model="formState"
        @finish="handleFinish"
        :rules="formRules"
      >
        <a-form-item name="phone">
          <a-input v-model:value="formState.phone" placeholder="请输入手机号" />
        </a-form-item>
        <a-form-item name="phoneCode">
          <div class="flex">
            <a-input
              class="mr-4"
              v-model:value="formState.phoneCode"
              placeholder="请输入验证码"
            />
            <a-button
              :disabled="phoneCodeSent"
              @click.prevent="sendPhoneCode"
              class="code-button"
            >
              {{ phoneCodeButtonLabel }}
            </a-button>
          </div>
        </a-form-item>
        <a-form-item>
          <a-button
            class="flex justify-center items-center login-button mx-auto mt-4"
            type="primary"
            html-type="submit"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
 import api from "@/utils/api.js";
 import { useRouter } from 'vue-router'
import { ref, reactive } from "vue";
import { message } from 'ant-design-vue';
const formRef = ref();
const formState = reactive({
    phone: "",
    phoneCode: "",
});
const router = useRouter()
const phoneCodeSent = ref(false);
const checkPhone =  async(_rule, value) => {
  if (!value) {
    return Promise.reject("手机号不能为空");
  } else {
    //获取工具类中的手机号正则表达式
    const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (phoneReg.test(value)) {
      Promise.resolve();
    } else {
      //如果验证输入错误就清空
      // this.form.phone = "";
      return Promise.reject("手机号格式错误");
    }
  }
};

const formRules = {
  phone: [{required: true,validator: checkPhone,trigger: "blur"}],
  phoneCode: [{required: true,message: "请输入验证码",trigger: "blur"}],
};

const sendPhoneCode = () => {
  // TODO: Implement phone verification code sending logic
  const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (formState.phone && phoneReg.test(formState.phone)) {
      api.get("/api/send/code?phone=" + formState.phone).then((res)=>{
        if (res.code == 0){
          phoneCodeSent.value = true;
          startCountdown();
        }else{
          message.error('验证码发送失败，请重新发送！')
        }
      })
      
    }else{
      message.error('请输入正确手机号')
    }
  
};

const handleFinish = () => {
  const url = `/api/pc/login?phone=${formState.phone}&code=${formState.phoneCode}`
  api.get(url).then((res)=>{
        if (res.code == 0){
          sessionStorage.setItem('token', res.data)
          setTimeout(()=>{
            router.push({
            path: '/'
          })
          },500)
         
        }else{
          message.error('登陆失败')
        }
      })
};

const phoneCodeButtonLabel = ref("获取验证码");
const countdown = ref(60);

const startCountdown = () => {
  phoneCodeButtonLabel.value = `${countdown.value}s`;
  const timer = setInterval(() => {
    countdown.value--;
    phoneCodeButtonLabel.value = `${countdown.value}s`;
    if (countdown.value === 0) {
      clearInterval(timer);
      phoneCodeButtonLabel.value = "获取验证码";
      countdown.value = 60;
    }
  }, 1000);
};


</script>

<style scoped lang="less">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f7f7f7;
}

.login-form {
  width: 400px;
  padding: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.captcha-input {
  display: flex;
  align-items: center;
}

.captcha-input img {
  margin-left: 10px;
  cursor: pointer;
}

.code-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.code-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
