export function  getIsAuthenticated(){
    if (sessionStorage.getItem('token')){
        return true
    }else{
        return false
    }
   
} 
export function  getToken(){
    return sessionStorage.getItem('token')
}