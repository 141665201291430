<!--
 * @Author: 周大波 zhiubo_1@163.com
 * @Date: 2023-07-03 15:27:58
 * @LastEditors: 周大波 zhiubo_1@163.com
 * @LastEditTime: 2023-07-13 18:49:17
 * @FilePath: /kbq-ChatGPT/src/components/Home.vue
 * @Description: 
-->
<template>
  <!-- <a-layout> -->
    <div>
      <div @click="toggleSidebar" class="toggleSidebar-hide cursor-pointer" v-if="isSidebarHidden">
          <layout-outlined />
        </div>
  
  <!-- <a-layout-sider  :style="{with: '260px' ,overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }"><Sidebar /></a-layout-sider> -->
  <a-row style="height: 100vh;overflow: hidden;">
        <a-col :span="isSidebarHidden ? 0 : 4">  
  <Sidebar
    ref="sidebarRef"
    @newChat="handleNewChat"
    @deleteChat="dandleDeleteChat"
    @handleChat="onHandleChatItem"
  /></a-col>
        <a-col :span="isSidebarHidden ? 24 : 20">  <div class="chat-container relative">
    <div class="chat-messages" id="chat-messages">
      <div
        v-for="(message, index) in messages"
        :key="message.id"
        class="message"
      >
      <div class="user-message">
          {{ message.problem }}
        </div>
      <div
          class="message-content"
          :class="
            index == messages.length - 1 && isWriting
              ? `message-content${index}  message-indinite`
              : `message-content${index}`
          "
        >
          <!-- {{ message.isUser ? message.problem : (isWriting && (messages.length - 1) == index) ? '' : message.problem }} -->
          {{ message.answer }}
          <div v-if="message.cases">
            <div class="text-xl">参考案例：</div>
            <div
              v-for="(sitem, sindex) in message.cases"
              :key="sindex"
              class="cursor-pointer text-blue-500"
              @click="onOpenWindown(sitem[1])"
            >
              {{ sitem[0] }};
            </div>
          </div>
        </div>
      
      
      </div>
    </div>
    <div class="chat-input-warp">
    <div class="chat-input">
      <!-- <input v-model="inputText" @keydown.enter="sendMessage" placeholder="Send a message" /> -->
      <!-- <textarea id="story" name="story" rows="1"  placeholder="Send a message" v-autoresize>
          </textarea> -->
      <!-- <textarea v-autoresize> -->
      <a-textarea
        placeholder="Send a message"
        v-model:value="inputText"
        :auto-size="{ minRows: 1, maxRows: 8 }"
        @pressEnter="sendMessage"
        @input="replaceNewLines"
      />
      <!-- <button @click="sendMessage">Send</button>
        <a-button type="primary">Send</a-button> -->
      <SendOutlined
        v-if="inputText.length == 0 && !isWriting"
        style="margin-bottom: 7px; margin-right: 7px; font-size: 18px"
      />
      <SyncOutlined
        v-if="isWriting"
        spin
        style="margin-bottom: 7px; margin-right: 7px; font-size: 18px"
      />
      <a-button
        type="primary"
        shape="default"
        @click="sendMessage"
        v-if="inputText.length > 0 && !isWriting"
      >
        <template #icon><SendOutlined /></template>
      </a-button>
    </div>
  </div>
  </div></a-col>
  </a-row>
</div>
  <!-- <a-layout> -->
  <!-- <a-layout-header>Header</a-layout-header> -->
  <!-- <a-layout-content> -->

  <!-- </a-layout-content> -->
  <!-- <a-layout-footer>Footer</a-layout-footer> -->
  <!-- </a-layout>
    </a-layout> -->
</template>
<script setup>
import { ref } from "vue";
import api from "@/utils/api.js";
import { LayoutOutlined } from "@ant-design/icons-vue";
import { SendOutlined, SyncOutlined } from "@ant-design/icons-vue";
import { generateUUID } from "@/utils/uuidGenerator.js";
import { connectSSE } from "@/utils/sseConnection.js";
import { message } from "ant-design-vue";
import { baseURL, baseProductURL } from "@/utils/config.js";
import Sidebar from "@/components/Sidebar.vue";
import { storeToRefs } from 'pinia';
// import store from "@/stores";
import { useChatStore } from "@/stores/chat";
// import { toRefs } from 'pinia';
const chatStore = useChatStore();
const { isSidebarHidden } = storeToRefs(chatStore)
const messages = ref([]);
const sidebarRef = ref()

// const chats = ref([])
const uuid = ref("");
const inputText = ref("");
const isWriting = ref(false);
console.log(chatStore);
const errorHandler = function errorHandler() {
  message.error("连接失败，请重新尝试！");
  isWriting.value = false;
};
// 定义自定义指令 v-autoresize
// const autoResizeDirective = {
//   mounted(el) {
//     adjustHeight(el);

//     // 监听输入事件，当内容改变时自动调整高度
//     el.addEventListener("input", () => {
//       adjustHeight(el);
//     });
//   },
// };

// 自动调整文本区域的高度
// function adjustHeight(textarea) {
//   textarea.style.height = "auto";
//   textarea.style.height = textarea.scrollHeight + "px";
// }

// export default {
// directives: {
//   autoresize: autoResizeDirective,
// },
// name: "ChatPage",
// components: {
//   SendOutlined,
//   SyncOutlined,
//   Sidebar,
// },
// data() {
//   return {
// chats: chatStore.getChat(),

//   };
// },
// mounted() {
// },

// created() {
//   this.newChat();
// },

// function adjustHeight(textarea) {
//   textarea.style.height = "auto"; // 先将高度设为auto，以便正确计算内容的高度
//   textarea.style.height = textarea.scrollHeight + "px"; // 设置文本区域的高度为内容的高度
// }
// methods: {

function addConnectSSE() {
  if(!uuid.value){
    uuid.value = generateUUID();
  }
  connectSSE(
    `${baseURL}api/sse/create/connect?clientId=${uuid.value }`,
    handleSSEEvent,
    errorHandler,
    connectSuccessHandler,
    closeSSE
  );
}

async function connectSuccessHandler(){
  try {
    const params = {
      clientId: uuid.value,
      message: inputText.value,
      maxTokens: chatStore.getMaxTokens(),
      model: chatStore.getModel(),
    };
    inputText.value = "";
    const response = await api.post("/api/sse/send/message", params);
    console.log(response, " successfully  messsage sse server");
    if (response.code == 1) {
      inputText.value = "";
      isWriting.value = false;
      message.error(response.msg || "请求失败，请重试！");
    }
  } catch (error) {
    inputText.value = "";
    isWriting.value = false;
    message.error("请求失败，请重试！");
    console.error(error);
  }
}

// 定义事件处理函数
function handleSSEEvent(data) {
  let isArray = false;
  if (messages.value.length > 0) {
    try {
      const _data = JSON.parse(data);
      if (Array.isArray(_data)) {
        isArray = true;
        messages.value[messages.value.length - 1].cases = _data;
      }
    } catch (error) {
      console.log(error);
    }
    if (data && data !== "[DONE]" && !isArray) {
      messages.value[messages.value.length - 1].answer =
        messages.value[messages.value.length - 1].answer + data;
    } else {
      isWriting.value = false;
    }
    console.log(document.getElementById('chat-messages').scrollHeight)
    document.getElementById('chat-messages').scrollTop = document.getElementById('chat-messages').scrollHeight
  }
}
// 处理空格函数
function replaceNewLines() {
  inputText.value = inputText.value.replace(/\n/g, "");
}
// 发送消息
async function sendMessage() {
  if (!inputText.value) {
    inputText.value = "";
    return;
  }
  isWriting.value = true;
  messages.value.push({
     answer: '',
     problem: inputText.value
  });
  addConnectSSE(uuid.value);
}

// eslint-disable-next-line no-unused-vars
async function closeSSE() {
  try {
    const params = {
      clientId: uuid.value,
    };
    const response = await api.get("/api/sse/close/connect", {
      params: params,
    }); // 发送GET请求
    console.log(response, " successfully closed connection");
  } catch (error) {
    console.error(error);
  }
}
function handleNewChat() {
  if (isWriting.value) {
    return;
  }
  console.log("handleNewChat");
  messages.value = [];
  isWriting.value = false;
  uuid.value = "";
  chatStore.updateIsChatIndex(-1);
}
function dandleDeleteChat(id) {
  if (isWriting.value) {
    return;
  }
  deleteChat(id)
  // chatStore.removeChat(index);
 
}
function deleteChat(id){
  api.get(`/api/chat/delete?id=${id}`).then(res=>{
    if(res.code == 0){
        messages.value = [];
        isWriting.value = false;
        uuid.value = "";
        message.success('删除成功！！')
        chatStore.updateIsChatIndex(-1);
        sidebarRef.value.getChats()
    }
  })
}
function getChat(id){
  api.get(`/api/chat/details?parentId=${id}`).then(res=>{
    if(res.code == 0){
      messages.value = res.data.map((item)=>{
        if(item.cases){
          item.cases = JSON.parse(item.cases)
        }
        return item
      })
      inputText.value = ''
      uuid.value = id
      isWriting.value = false;
    }
  })
}
function onHandleChatItem(id ,index) {
  if (isWriting.value) {
    return;
  }
  chatStore.updateIsChatIndex(index);
  getChat(id)
}
// async connectSSE () {
//   try {
//     const params = new URLSearchParams()
//     params.append('clientId', uuid.value)
//     console.log(params.toString())
//     const response = await api.get('/api/sse/create/connect', {
//       params: params
//     }) // 发送GET请求
//     console.log(response ,' successfully  connect sse')
//   } catch (error) {
//     console.error(error)
//   }
// },

//   },
// };
function onOpenWindown(url) {
  const _url = `${baseProductURL}${url}`;
  window.open(_url, "_blank");
}
function toggleSidebar() {
      chatStore.updateIsSidebarHidden(!isSidebarHidden.value)
}
</script>

<style scoped lang="less">
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  font-size: 18px;
}
.toggleSidebar-hide {
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-radius: 0.375rem;
  width: 42px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
    top: 20px;
    left: 40px;
    z-index: 100;
}
.chat-messages {
  /* flex: 1; */
  /* overflow-y: auto; */
  padding-bottom: 14vh;
  box-sizing: border-box;
  /* padding-bottom: 180px; */
  height: 100vh;
  overflow-y: scroll;
}

.message {
  margin-bottom: 10px;
}
.message-indinite::after {
  content: "_";
  animation: blink 1s infinite;
}
@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.message-content {
  padding: 40px 25%;
  background-color: #f7f7f8;
}

.user-message {
  background-color: white;
  padding: 40px 25%;
}
.chat-input-warp{
  position: absolute;
  height: 16vh;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  border: 0 solid #d9d9e3;
}
.chat-input {
  display: flex;

  align-items: end;
  width: 66%;
  /* left: 25%; */
  padding: 16px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
}
.chat-input textarea:focus {
  border: none;
  box-shadow: none;
}
.chat-input textarea {
  flex: 1;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgb(142, 142, 160);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-width: 1;
  border-left-color: rgb(142, 142, 160);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgb(142, 142, 160);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgb(142, 142, 160);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  color-scheme: light;
  column-count: auto;
  cursor: text;
  display: block;
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variation-settings: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  max-height: 200px;
  overflow-wrap: break-word;
  overflow-y: hidden;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 48px;
  padding-top: 0px;
  resize: none;
  /* tab-size: 4; */
  text-align: start;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  grid-template-columns: preserve;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;
  border: none;
  resize: none;
  /* 去除选中后的边框 */
  outline: none;
  overflow: hidden; /* 隐藏超出文本区域的内容 */
  /* border-radius: 5px 0 0 5px;
    border: 1px solid gray; */
}

.chat-input button {
  /* padding: 10px;
    border-radius: 0 5px 5px 0;
    border: none;
    background-color: lightgray; */
  height: 32px;
}
</style>
