<!--
 * @Author: 周大波 zhiubo_1@163.com
 * @Date: 2023-07-11 17:39:06
 * @LastEditors: 周大波 zhiubo_1@163.com
 * @LastEditTime: 2023-07-13 18:49:30
 * @FilePath: /kbq-ChatGPT/src/components/CaseDetails.vue
 * @Description:  案例详情
-->
<template>
  <div class="container-warp">
    <a-row class="h-full bg-[#f7f7f7]">
        <a-col :span="6"></a-col>
        <a-col :span="12" class="bg-white my-10">
            <div class="case-info px-10 py-20">
                <!-- 标题 -->
                <div class="text-center text-xl">{{ caseInfo && caseInfo.title || ''  }}</div>
                <!-- 基本信息 -->
                <div class="basic-info">
                    <div class="title text-[#3771B4]">
                        基本信息
                    </div>
                    <div class="basic-list flex flex-wrap" v-if="caseInfo && caseInfo.caseInfo">
                        <div class="basic-item">
                            <div class="label">案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.casecause || '' }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">法&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;院：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.court || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.caseid || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">案件类型：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.casetype || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">文书类型：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.doctype || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">裁判日期：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.judgedate || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">审理程序：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.procedure || ''  }}</div>
                        </div>
                        <div class="basic-item">
                            <div class="label">参考类型：</div>
                            <div class="value">{{ caseInfo && caseInfo.caseInfo && caseInfo.caseInfo.referencetype || ''  }}</div>
                        </div>
                    </div>
                </div>
                <!-- 详情 -->
                <div class="info-warp">
                    <div v-for="(item,index) in caseInfo && caseInfo.caseInfo &&  caseInfo.caseInfo.paragraphs" :key="index" class="info-item">
                        <div class="title text-[#3771B4]">
                            {{ item.tag || ''  }}
                        </div>
                        <div class="content indent-lg">
                            {{ item.content || ''  }}
                        </div>
                    </div>
                </div>
                <!-- 相关法条 -->
                <div class="provisions-warp">
                    <div class="title text-[#3771B4]">
                            相关法条
                        </div>
                    <div class="provisions-item" v-for="(item,index) in caseInfo && caseInfo.lawInfo" :key="index">
                        <div class="font-bold py-2">{{ item.title || ''  }}</div>
                        <div class="item indent-lg" v-for="(sitem,sindex) in caseInfo && item.content" :key="sindex">
                            {{  sitem.lawInfo || '' }}
                        </div>
                    </div>
                </div>
            </div>
        </a-col>
        <a-col :span="6"></a-col>
  </a-row>
  </div>
</template>

<script  setup>
import { ref } from 'vue'
import {  useRoute } from 'vue-router';
import api from '@/utils/api.js';
import { message } from 'ant-design-vue';

const route = useRoute()
const caseId = ref('')
const caseInfo = ref()
console.log(route)
if(route.params.id){
    caseId.value = route.params.id
    getCaseDetails()
}
function getCaseDetails(){
    api.get(`/api/case/info/${caseId.value}`).then(res =>{
        if(res.code == 0){
            caseInfo.value = res.data
           
        }else{
            message.error(res.msg || '请求失败')
        }
    })
}
</script>
<style scoped lang="less">
.provisions-warp{
    .title{
        padding-left: 10px;
        height: 30px;
        line-height: 30px;
       
      }
      .title::before{
        content: '';
        position: absolute;
        width: 4px;
        height: 30px;
        left: 40px;
        background-color: #3771B4;
    }
    .provisions-item{
        margin: 15px 0;
        padding: 0 10px;
     
    .content{
        padding-top: 15px;
    }
   
    }
}
.info-warp{
    .info-item{
        margin: 15px 0;
        padding: 0 10px;
        .title{
        padding-left: 10px;
        height: 30px;
        line-height: 30px;
       
    }
    .content{
        padding-top: 15px;
    }
    .title::before{
        content: '';
        position: absolute;
        width: 4px;
        height: 30px;
        left: 40px;
        background-color: #3771B4;
    }
    }
}
.basic-info{
    margin-top: 40px;
    .title{
        padding-left: 20px;
        height: 30px;
        line-height: 30px;
       
    }
   
    .title::before{
        content: '';
        position: absolute;
        width: 4px;
        height: 30px;
        left: 40px;
        background-color: #3771B4;
    }
    .basic-list{
        padding: 26px 0;
        .basic-item{
            width: 50%;
            padding-left: 40px;
            margin: 10px 0;
            display: flex;
            .label{
                width: 70px;
                font-weight: bold;
            }
            
          
        }
    }

}

</style>