
import axios from 'axios';
import { baseURL } from '@/utils/config.js'
import { getToken } from '@/utils/util.js'

// import store from '@/stores'

// import { useChatStore } from "@/stores/chat";
// const chatStore = useChatStore(store)

// 创建axios实例
const api = axios.create({

  baseURL,
  timeout: 120000, // 请求超时时间
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    // 可在此处进行请求拦截的操作，例如添加请求头、设置token等
    console.log(getToken())
    config.headers.Authorization = getToken()
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    // 可在此处进行响应拦截的操作，例如处理错误码、统一处理返回数据等
    // eslint-disable-next-line no-debugger
    
    if (response.data.code == 401){
      // chatStore.updateIsShowLogin(true)
      const url = `/api/visitor/login`
      api.post(url).then((res)=>{
            if (res.code == 0){
              sessionStorage.setItem('token', res.data)
            }
          })
    }else{
      return response.data;
    }
  },
  (error) => {
    // eslint-disable-next-line no-debugger
    return Promise.reject(error);
  }
);

export default api;
