<template>


  <div
    :class="['sidebar', { hidden: isSidebarHidden }]"
    :style="{ height: sidebarHeight + 'px;' }"
  >
    <div class="top-section flex justify-between">
      <div
        class="text-[16px] flex pl-[12px] cursor-pointer items-center rounded-md border-[1px] border-[hsla(0,0%,100%,.2)] w-[162px] h-[44px]"
        @click="handleNewChat"
      >
        + New chat
      </div>
      <div @click="toggleSidebar" class="toggleSidebar-show cursor-pointer" v-if="!isSidebarHidden">
        <layout-outlined />
      </div>
    </div>
    <div class="chat">
      <!-- <h3>Chat</h3> -->
      <div class="chat-list overflow-y-scroll">
        <div  v-for="(message, index) in chats" :key="index" >
            <div class="cursor-pointer" :class="index == isChatIndex ? 'active chat-item': 'chat-item'"  @click="handleChatItem(message.id, index)">
                <div class="max-w-[168px] overflow-hidden whitespace-nowrap overflow-ellipsis"> {{  message.title || '' }}</div>
            <delete-outlined @click.stop="handleDeleteChat(message.id, index)" class="cursor-pointer" v-if="index == isChatIndex" />
            </div>
           
        </div>
      </div>
    </div>
    <div class="bottom-section">
      <a-divider
        style="height: 1px; background-color: hsla(0, 0%, 100%, 0.2);margin-top: 10px;margin-bottom: 20px;"
      />
   
      <div class="select-row">
        <label class="text-[14px] w-[82px] text-right">模型(选择):</label>
        <a-select v-model:value="model" style="width: 120px" @change="onModelChange">
          <a-select-option :value="item.value" :disabled="item.isDisabled" v-for="(item , index) in modelOptions" :key="index">{{ item.value }}</a-select-option>
          <!-- <a-select-option value="Vicuna">Vicuna</a-select-option> -->
        </a-select>
      </div>
      <div class="select-row">
        <label class="text-[14px]">最大token数:</label>
        <a-select
          v-model:value="maxTokens"
          style="width: 120px"
        >
          <a-select-option
            v-for="length in lengthOptions"
            :key="length"
            :value="length"
            >{{ length }}</a-select-option
          >
        </a-select>
      </div>
      <!-- <div class="select-row pl-[88px]">
        <div><a-button type="primary" @click="initChat()">初始化(谨慎操作)</a-button></div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { LayoutOutlined,DeleteOutlined } from "@ant-design/icons-vue";
// import store  from "@/stores";
import { useChatStore } from "@/stores/chat";
import {  ref, defineEmits, defineExpose } from 'vue'
import { maxTokensLength, modelOptions } from '@/utils/config.js'
import { getIsAuthenticated } from '@/utils/util.js'
import { storeToRefs } from 'pinia';
import api from '@/utils/api.js';
import { message } from "ant-design-vue";
const emit = defineEmits(["newChat","deleteChat", 'handleChat']);
const chatStore = useChatStore()
   const { chat, isChatIndex, maxTokens, model, isSidebarHidden } = storeToRefs(chatStore)
   const chats = ref([])
   const   lengthOptions= ref(maxTokensLength)
   const   sidebarHeight = ref(0)
//    maxTokens.value = chat
      console.log(isChatIndex)
      console.log(chat)
    sidebarHeight.value = window.innerHeight;

    function toggleSidebar() {
      chatStore.updateIsSidebarHidden(!isSidebarHidden.value)
    }
    function handleNewChat() {
       emit("newChat")
       getChats()
    }
    function handleDeleteChat(id,index){
        emit("deleteChat",id ,index)
    }
    function handleChatItem(id,index){
        emit("handleChat", id,index)
    }
    // function initChat(){
    //     chatStore.reset()
    //     emit("deleteChat", 1)
    // }
    function onModelChange(){
      handleNewChat()
    }
    function getChats(){
      chats.value = []
      api.get('/api/chat/list').then(res=>{
        if(res.code == 0){
          chats.value = res.data.rows
        }else{
          message.error(res.msg || '请求失败')
        }
      })
    }
    function handleLogin() {
      if (getIsAuthenticated()){
        getChats()
        return
      }
      const url = `/api/visitor/login`
      api.post(url).then((res)=>{
            if (res.code == 0){
              sessionStorage.setItem('token', res.data)
              getChats()
            }else{
              message.error('登陆失败')
            }
          })
    }
    handleLogin()
    defineExpose({
      getChats,
     
    })
</script>

<style scoped lang="less">
.chat {

.chat-list{
 margin-top: 20px;
 height: 76vh;
 padding: 30px 0px;
  ::-webkit-scrollbar {
    display: none;
  }
  .chat-item{
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      padding: 8px;
      color: #fff;
      justify-content: space-between;
  }
  .active{
      background-color: rgba(52,53,65,1);
      border-radius: 0.375rem;
  }
}

}
.sidebar {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(32, 33, 35, 1);
  color: #fff;
  padding: 8px;
  transition: transform 0.3s;
  /* position: fixed;
  z-index: 100; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.hidden {
  transform: translateX(-100%);
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-section button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}



h2,
h3 {
  margin-top: 0;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.bottom-section {
  display: flex;
  flex-direction: column;
 
}

.select-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

input {
  padding: 4px;
}
.toggleSidebar-hide {
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-radius: 0.375rem;
  width: 42px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
}
.toggleSidebar-show {
  border-color: hsla(0, 0%, 100%, 0.2);
  border-width: 1px;
  border-radius: 0.375rem;
  width: 42px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
