

// sseConnection.js
// import { getToken } from '@/utils/util.js'

// import {EventSourcePolyfill} from 'event-source-polyfill';
// import EventSource from "eventsource";
export function connectSSE(url, eventHandler, errorHandler, connectSuccessHandler, closeSSE) {
    // const eventSource = new EventSource(url,{
    //   headers: {
    //         Authorization: getToken()
    //       }
    // });
    // console.log(window.EventSource)
    // console.log(window.EventSourcePolyfill)
    // var eventSourceInitDict = {headers: { Authorization: getToken()}};
    // let eventSource = new EventSource(url, eventSourceInitDict);
    // const eventSource = new EventSourcePolyfill(url, {
    //   headers: {
    //     'Authorization': getToken()
    //   }
    // })
    // const eventSource = new EventSourcePolyfill(url)
    const eventSource = new EventSource(url)
    console.log(eventSource)

    eventSource.onopen = function(event){
      //  eventSource.close()
      console.log("连接成功", event);
      connectSuccessHandler()
    }
    eventSource.onmessage = function(event) {
        console.log(event)
        // eslint-disable-next-line no-debugger
      const eventData = event.data
      eventHandler(eventData);
      // 回答问题结束需要关闭连接
      if(eventData == "[DONE]"){
        eventSource.close()
        closeSSE()

      }
    };
  
    eventSource.onerror = function(error) {
      console.error('SSE Error:', error);
      errorHandler(error)
    };
  
// var es = new EventSourcePolyfill(url, {
//   headers: {
//     authorization: getToken(),
//   },
// });
// console.log(es)
// eventHandler()
// errorHandler()
// es.addEventListener("message", (event) => {
//   console.log("receiving data from server:", JSON.parse(event.data));
// });
  }
  