<!--
 * @Author: 周大波 zhiubo_1@163.com
 * @Date: 2023-07-13 10:35:03
 * @LastEditors: 周大波 zhiubo_1@163.com
 * @LastEditTime: 2023-07-13 13:34:53
 * @FilePath: /kbq-ChatGPT/src/components/ModalLogin.vue
 * @Description: modallogin
-->
<template>
  <div>
    <a-modal v-model:visible="isShowLogin" :footer="null" style="width: 340px;height: 320px;overflow: hidden;">
        <wxlogin :appid="appid" :scope="'snsapi_login'" :theme="'black'" :redirect_uri="redirect_uri" href='data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==' rel="external nofollow"></wxlogin>
    </a-modal>
  </div>
</template>

<script  setup>
import wxlogin from 'vue-wxlogin'
import { useChatStore } from "@/stores/chat";
import { WxLogin } from '@/utils/config.js';
import { storeToRefs } from 'pinia';
const chatStore = useChatStore()
const { isShowLogin } = storeToRefs(chatStore)
const {appid, redirect_uri} = {
    ...WxLogin
}


</script>
<style scoped lang="less">
</style>