
export const baseProductURL =   'https://chat.kbanquan.com' // 根据实际情况设置基础URL

// export const baseURL =   'http://103.205.5.9:8090/' // 根据实际情况设置基础URL

// export const baseURL =   'https://312p6028w2.zicp.fun/' // 根据实际情况设置基础URL
export const baseURL =   'https://chat.kbanquan.com/' // 根据实际情况设置基础URL


export const maxTokens =   500

export const maxTokensLength =   [100, 200, 300, 400, 500]

export const modelOptions = [
    {
        value: 'ChatGPT',
        isDisabled: false
    },
    {
        value: 'Vicuna',
        isDisabled: false
    }
]

export const modelCustom = 'Vicuna'

export const WxLogin = {
    appid: 'wx79cdf49ee5daf113',
    redirect_uri: 'https://chat.kbanquan.com'
}
   