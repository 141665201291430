


import { defineStore } from 'pinia';
import { maxTokens, modelCustom } from '@/utils/config.js'

export const useChatStore = defineStore('chat', {
  state: () => {
   return {
      isChatIndex: -1,
      maxTokens,
      model: modelCustom,
      isShowLogin: false,
      isSidebarHidden: false
   }
  },
  persist: {
    // 开启持久化
    enabled: true,
    // 选择存储方式和内容
    strategies: [
      { storage: sessionStorage, paths: [ 'isChatIndex', 'maxTokens', 'model', 'isShowLogin', 'isSidebarHidden'], }
    ]
  },
  actions: {
    getMaxTokens() {
      return this.maxTokens;
    },
    getIsShowLogin() {
      return this.isShowLogin;
    },
    getModel() {
      return this.model;
    },
    getIsChatIndex(){
      return this.isChatIndex;
    },
    getIsSidebarHidden(){
      return this.isSidebarHidden;
    },
    updateIsShowLogin(state) {
      this.isShowLogin = state;
    },
    updateIsChatIndex(index) {
      this.isChatIndex = index;
    },
    updateIsSidebarHidden(state) {
      this.isSidebarHidden = state;
    },
    reset(){
      this.isChatIndex = -1
      this.maxTokens = maxTokens
      this.model = modelCustom
      this.isShowLogin = false
      this.isSidebarHidden = false
    }
  },
   
});